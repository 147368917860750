@import "node_modules/bootstrap/scss/bootstrap";

// ----------------------------------------------------------------------
// Variables
// ----------------------------------------------------------------------

$white: #fff;
$black: #000;
$orange: #f66328;
$brown: #686158;

$dgray: #555;
$bgray: #6d6d6d;
$lgray: #d8d8d8;
$lbrown: #988c88;
$llbrown: #c7beba;
// ----------------------------------------------------------------------
// Fonts
// ----------------------------------------------------------------------

@function calculate-rem($size) {
    $rem-size: $size / 16px;
    @return $rem-size * 1rem;
}

@mixin font-size($size) {
    font-size: calculate-rem($size);
}

// How to use: @include font-face("MyFont", "path/to/MyFont", $style: normal, $weight: normal);
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
    @font-face {
        font-family: quote($font-name);
        src: url($file-name + '.eot');
        src: url($file-name + '.eot?#iefix') format('embedded-opentype'),
        url($file-name + '.woff') format('woff'),
        url($file-name + '.ttf') format('truetype'),
        url($file-name + '.svg##{$font-name}') format('svg');
        font-weight: $weight;
        font-style: $style;
    }
}
@font-face {
    font-family: 'GillSansStd-Light';
    src: url('../font/GillSansStd-Light.otf');
    font-weight: 100;
}
@font-face {
    font-family: 'GillSansStd-regular';
    src: url('../font/GillSansStd.otf');
    font-weight: 400;
}
@font-face {
    font-family: 'GillSansStd-Bold';
    src: url('../font/GillSansStd-Bold.otf');
    font-weight: 700;
}
@font-face {
    font-family: "YaquoteScript";
    src: url("../font/YaquoteScript.ttf");
    font-weight: 300;
}

$font-light: 'GillSansStd-Light',sans-serif;
$font-bold: 'GillSansStd-Bold',sans-serif;
$font-regular: 'GillSansStd-regular',sans-serif;
$font-fancy: "YaquoteScript", sans-serif;
h1, h2, h3, h4, h5, h6 {margin: 0px; font-family: $font-light !important;}

.fancy {
	font-family: $font-fancy !important;
	color: $orange;
	font-size: 5rem;
	margin-bottom: 0px !important;
}
.title, .v65-productGroup-title, .intro h1, #main .container h1 {
	font-family: $font-light !important;
	color: $orange;
	text-transform: uppercase;
	font-size: 2.2rem;
	text-align: center;
	margin-bottom: 0px !important;
	@extend .py-5;
}
.subtitle, #clubs h2 {
	color: $black;
	font-family: $font-regular;
	font-size: 1.7rem;
	margin-bottom: 0px !important;

}
hr {
	border-top: 1px solid $brown;
	border: 0;
    border-collapse: collapse;
    clear: both;
}

.divider-top {
    border-top: 1px solid $brown;

    margin-top: 50px;
}

.divider-bottom {
    border-bottom: 1px solid $brown;
}

body {
	font-family: $font-light !important;
	font-weight: 300;
}

p strong {font-family: $font-regular;}
a {
	color: $orange;
	transition: all .3s;
	&:hover {
		color: $orange;
	}
}

.bg-dark {
	background: $brown !important;
	color: $white;
}

.btn, .card {
	border-radius: 0px;
}


.btn-primary {
	background-color: $orange;
	border: none;
	text-transform: uppercase;
	transition: all 0.3s;
	-webkit-appearance: none;
	&:hover {
		background-color: darken($orange,10%);
		border-color:  darken($orange,10%);
	}
	&:active {
		background-color: darken($orange,10%);
		border-color:  darken($orange,10%);
	}
	&:focus {
		background-color: darken($orange,10%);
		border-color:  darken($orange,10%);
		outline: darken($orange,10%);
	}
}

%no-bullets {
	padding-left: 0px;
	list-style-type: none;
}

%responsive {
	width: 100%;
	height: auto;
}

.social-icons {
	ul {
		@extend %no-bullets;
		li {
			margin-right: 0px;
			padding: 0px 25px;
			a {
				color: $dgray;
				&:hover {
					color: lighten($dgray,20%);
				}
				span.fab {padding: 0px;}
			}
		}
	}
}

.padding-sm {
	padding: 20px 0px;
}
.padding-md {
	padding: 30px 0px;
}
.padding-lg {
	padding: 50px 0px;
}

.margin-sm {
	margin: 20px 0px;
}
.margin-md {
	margin: 30px 0px;
}
.margin-lg {
	margin: 50px 0px;
}


// ----------------------------------------------------------------------
// TEMPLATE
// ----------------------------------------------------------------------

html {
  scroll-behavior: smooth;
}

header.product-drilldown2 #header-top .container .social-icons ul.v65-socialMediaLinks {margin-top:3px;}

header {
	background: $white;
	@media (min-width: 992px) {
		padding: 15px 0px 8px;
	}
	#header-top {

		display: block;

		.container {
			@media (min-width: 992px) {
				display: flex;
				justify-content: flex-end;
			}

			.user-tools {
				@media (max-width: 991px) {
					float: none;
					text-align: left;
				}
				@media (min-width: 992px) {
				display: flex;
				}
				a {
					text-decoration: none;
					color: $dgray;
				}

			}


			#v65-modalCart, .v65-login, .telephoneNumber, .social-icons {
				margin-left: 20px;
				@media (max-width: 991px) {
					display: inline-block;
					text-align: left;
					float: none;
					margin: 0px 20px 0px 0px;
					width: auto;

				}
				a {
					color: $black;
					text-decoration: none;
					color: $dgray;
					text-align: left;
				}

			}
			.v65-yourAccount {
				@media (max-width: 991px) {
					display:none;
				}
			}
			.v65-login {line-height:2em;}
			.icon-person, .icon-cart, .icon-phone {
				color: $orange;
			}
			.icon-phone {
				padding: 0px;
    			font-size: 15px;
			}
			.telephoneNumber {
				@media (max-width: 575px) {
					display: none;
				}
			}
			#v65-modalCart {
				@media (max-width: 991px) {
					top: unset;
					right: unset;
					position:relative;

				}
				a {
					@media (max-width: 991px) {
						float: none;
						width: auto;
						margin: 0px;
					}
				}
				#v65-modalCartDropdown {
					@media (max-width:991px) {
						min-width: 282px;
						right: unset;
						left: -72px;
					}
				}
			}
			.social-icons {
				@media (max-width: 991px) {
					display: none;
				}
				ul.v65-socialMediaLinks {
					margin-top: -5px;
					li {
						padding: 0px 5px;
						a {
							span.fab {
								font-size:0.9rem;
							}
						}
					}
				}
			}
		}

	}



	#header-bottom {
		padding: 0px !important;
		background-color: $white;
		z-index: 999;
		@media (max-width: 991px) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}
		&.fixed-top {
			border-bottom: 1px solid $lbrown;
		}
		.navbar-brand {
			max-width: 200px;
			width: 100%;
			@media (min-width: 576px) {
			}
			@media (min-width: 768px) {
				max-width: 240px;

			}
			img {
				width: 100%;
			}
		}
		.navbar-toggler {
			border: none;
			font-size: 0.5rem;
			.navbar-toggler-icon {
				height: 2rem;
				font-size: 1rem;
				@media (min-width: 576px) {
					font-size: 0.5rem;
					height: 20px;
				}
			}


		}
		.navbar-collapse {
			margin-top: 30px;
			@media (max-width: 991px) {
				margin-top: 0px;
				background-color: $brown;
				padding: 0px !important;
				z-index: 999;
				overflow-y: scroll;
			}
			@media (min-width: 992px) {
				justify-content: flex-end;

			}
		}

		.v65-layoutHeaderNav {
			@extend .navbar-nav;

			li {
				@extend .nav-item;
				position: relative;
				@media (max-width: 991px) {
					border-bottom: 1px solid darken($brown,10%);
				}

				a {
					@extend .nav-link;
					@media (max-width: 768px) {
						width: 100%;
					}
					@media (max-width: 991px) {
						color: $white !important;
						padding: 10px 15px;
					}
					&:hover {
						color: $orange !important;
					}
				}
				&:hover ul {
					@media (min-width: 992px) {
						position: absolute;
						display: block;
						top: 40px;

					}
					@media(min-width: 992px) {

					}

				}
				ul {
					background-color: transparent;
					display: block;
					padding: 0px 0px 0px 20px;
					border: none;
					border-radius: 0px;
					transition: all .3s;
					z-index:999;
					@extend .dropdown-menu;
					@media (min-width: 992px) {
						display: none;
						background-color: $llbrown;
						padding: 0px;

					}
					a {
						@media (min-width: 992px) {
							padding: 0.5rem 1rem;
						}
					}
					li:hover {
						@media (min-width: 992px) {
							background-color: $white;
						}
					}
					li:first-child {
						@media (max-width: 991px) {
							border-top: 1px solid darken($brown,10%);
						}

					}
					li:last-child {
						border-bottom: none;
						a {
							@media (min-width: 992px) {

							}
						}
					}
				}
			}
			li:last-child {
				a {padding-right: 0px;}
			}
		}
		.utilities {
			padding: 30px 15px 20px;
			@media (min-width: 992px) {
				display: none;
			}
			.v65-yourAccount {display: none;}
			#v65-modalCart {
				a {
					float: none;
				}
			}
			.user-tools, #v65-modalCart, .v65-login, .telephoneNumber {
				display: inline-block;
				position: relative;
				margin-right: 12px;
			}
			a {
				color: $white;
				text-decoration: none;
				span {
					color: $lbrown !important;
					padding-left: 0px;
				}
			}

			.social-icons {
				ul.v65-socialMediaLinks {
					li {
						padding: 0px 5px;
						a {
							color: $white;
							span.fab {
								font-size:1rem;
								color: $white !important;
							}
						}
					}
				}
			}
		}

		@media (max-width: 992px) {
		    .navbar-collapse {
		        position: absolute;
		        top: 65px;
		        right: 100%;
		        padding-left: 15px;
		        padding-right: 15px;
		        padding-bottom: 15px;
		        width: 100%;
		        transition: all 0.3s ease;
		        display: block;
		    }
		    .navbar-collapse.collapsing {
		        height: auto !important;
		        margin-right: 50%;
		        transition: all 0.3s ease;
		        display: block;
		    }
		    .navbar-collapse.show {
		        right: 0;
		    }
		}
	}
}

#banner {
	img {
		@extend %responsive;
	}
}

#nav-secondary {
	background: rgba(56, 53, 50, 0.76);
	transition: all .3s;
	&.fixed-top {
		top: 83px;
		@media (min-width: 768px) {
				top: 94px;
		}
	}
	.navbar-toggler {
		width: 100%;
	    color: #fff;
	    font-size: 1rem;
	    font-weight: bold;
	    text-align: left;
	    padding: 12px 0px;
	    height: auto;
	    text-transform: uppercase;
	    display: block;
	    @media (min-width: 992px) {
	    	display: none;
	    }
		::after {
	    display: inline-block;
	    margin-left: 0.255em;
	    vertical-align: 0.255em;
	    content: "";
	    border-top: 0.3em solid;
	    border-right: 0.3em solid transparent;
	    border-bottom: 0;
	    border-left: 0.3em solid transparent;
		}

	}
	.navbar-toggler-icon {
		width: auto;
	    height: auto;
	    margin-top: -4px;
	    padding: 0px;
	    line-height: 0px;
	    font-size: 16px;
	}
	.navbar {
		padding: 0px;
		ul {
		@extend %no-bullets;
		@media (min-width: 992px) {
			display: flex;
			margin: 0px;
		}
		li ul {
			display: none;
		}
		li:first-child a {
			@media (min-width: 992px) {
				padding-left: 0px;
			}
		}
			a {
				color: $white;
				text-decoration: none;
				text-transform: uppercase;
				font-size: 14px;
				@media (max-width: 991px) {
					display: inline-block;
					width: 100%;
					padding: 5px 0px;
				}
				@media (min-width: 992px) {
					padding: 8px 12px;
    				display: inline-block;
				}
				&:hover {
					color: $llbrown;
					text-decoration: underline;
				}
				&:active {
					color: $white;
					text-decoration: underline;
				}
			}
		}
	}

	.navbar-expand-md {
		@media (max-width: 991px) {
			flex-wrap: wrap;
		}
		.navbar-collapse {
			@media (min-width: 992px) {
				display: none;
			}

		}
	}

}

#intro {
	h1 {
		text-align: center;
		color: $orange;
		text-transform: uppercase;
		margin-bottom :0px;
		font-family: $font-light !important;
		@extend .padding-lg;
		display: block;
	    text-align: center;
	    width: 100%;
	}
}


#newsletter {
	display: block !important;
	position: relative;
	background: url(https://fortberensredesign.uswest2.vin65dev.com/assets/fortberens/images/footer-bg1.png) center center no-repeat;
	background-size: cover;
	text-align: center;
	padding: 90px 0px;
	margin-top: 100px;
	#icon-fb {
		position: absolute;
		top: -43px;
		left: 50%;
		margin-left: -43px;
		width: 85px;
		height: 85px;
		background: url(https://fortberensredesign.uswest2.vin65dev.com/assets/fortberens/images/footer-logo.png) no-repeat 0px 0px;
		background-size: cover;
		@media (min-width: 768px) {
			top: -47px;
		left: 50%;
		margin-left: -47px;
		width: 95px;
		height: 95px;
		}
	}
	.container {
		.row {
			padding-top: 50px;
		}
		h3 {
			font-size: 2.1875rem;
			text-transform: uppercase;
			font-family: $font-regular !important;
		}

		input {
			padding: 6px 10px 5px;
			border: 1px solid $lgray;
			@media (max-width: 576px) {
				width: 100%;
			}
		}
		button {
			@extend .btn;
			@extend .btn-primary;
			border: 1px solid $orange;
			@media (max-width: 576px) {
				width: 100%;
			}
			span {
					height:auto;
					line-height: 1rem;
					padding: 0px 20px;
			}
		}

	}


}


footer {
	@extend .py-5;
	background: url(../img/footer.png) no-repeat bottom right;
	background-color: $brown;
	color: $white;

	h3 {
		text-transform: uppercase;
		border-bottom: 1px solid $white;
		font-size: 1.125rem;
		font-family: $font-light !important;
		color: $white !important;
		margin-bottom: 20px;
		@extend .py-3;
	}
	p {
		font-size: 0.9375rem;
	}

	ul {
		@extend %no-bullets;
		a {
			text-decoration: none;
			&:hover {
				color: $white;
				text-decoration: underline;
			}
		}
	}

	a {
		color: $white !important;
		&:hover {
			color: $white;
			text-decoration: none;
		}
	}

	.copyright {
		font-size: 0.875rem;
		padding-top: 20px;
		color: $white !important;
	}
}

.backToTop {
	-ms-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    border: 3px solid #736a49;
    bottom: 20px;
    color: #736a49;
    border-radius: 50%;
    font-size: 0.9em;
    height: 1.3em;
    line-height: 1.3em;
    padding: 1em;
    position: fixed;
    right: 20px;
    text-align: center;
    text-decoration: none;
    width: 1.3em;
    z-index: 1000;
    .icon-arrow-up {
		padding: 0;
    font-size: 0.95em;
    height: 1.3em;
    line-height: 1.3;
    color: #736a49;
    margin-top: -8px;
    margin-left: -7px;
    display: block;
	}
}

.fixed-top {
	z-index:9;
}


.border {
	border: 1px solid $brown !important;
}



#prefooter-wines {
	padding-bottom: 50px;
	hr {
		margin-bottom: 50px;
	}
	.row {
		@media (max-width: 575px) {
			display: block;
		}

		.col {

			h3 {
				font-family: $font-regular;
			}
			.bg-dark {


				@media (min-width: 576px) and (max-width: 767px) {
					height: inherit !important;
					margin-bottom: 20px;
					padding-bottom: 20px;
				}
				h3 {
					color: $white !important;
				}

			}

			div {
				padding: 20px;
				margin-bottom: 20px;
				@media (min-width: 576px) {
					padding: 20px 20px 10px;
				}
				@media (min-width: 768px) {
					padding: 20px 20px 10px;
				}

			}
			&:first-child {
				@media (min-width: 576px) and (max-width: 767px) {
					flex: none;
				}
			}
		}
	}
	.container:nth-child(2) {
		.row {
			padding-top: 30px;
			@media (min-width: 992px) {
			}
		}
	}

}





// ----------------------------------------------------------------------
// HOME
// ----------------------------------------------------------------------

#home-banner {
	position: relative;
	padding-bottom: 30px;
	#notification-banner {
		background: $lbrown;
		padding: 10px 0px;
		p {
			color: $white;
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 1px;
			margin-bottom: 0px;


			a {
				color: $white;
				&:hover {
					text-decoration: none;
				}
			}
		}

	}

	#home-carousel {
		.carousel-indicators [data-bs-target] {
			background-color: $lbrown !important;
		}
		.carousel-inner {
			padding-bottom: 50px;
			margin-bottom: 0px;
		}
		.carousel-item {
			min-height: 320px;

			@media (min-width: 576px) {
				min-height: 450px;
			}
			@media (min-width: 992px) {
				min-height: 550px;
			}
			@media (min-width: 1200px) {
				min-height: 600px;
			}
			&::after {
				background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0)),to(rgba(0,0,0,.65)));
    			background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.65) 100%);
			}
			p {
				text-shadow: 0px 0px 10px #000000;
				margin-bottom: 10px;
			}
			.title {
				font-size: 1.7rem;
				color: $white;
				padding: 0px !important;
				letter-spacing: 1px;
				line-height: 2rem;
				@media (min-width: 768px) {
					font-size: 2rem;
				}
				@media (min-width: 992px) {
					font-size: 2.5rem;
				}
			}
			.displayYes {display:inline-block;}
			.displayNo {display:none;}

		}
		.slick-dots {
			bottom: 0px;
			button {
				border-radius: 50%;
				width: 12px !important;
				height: 12px !important;
				background: $lbrown !important;
				border: none !important;
			}

		}
	}
}

#home-intro {
	img {
		opacity: 0.6;
		max-width: 680px;
		width: 100%;
		padding: 30px 0px 60px;
	}

	.box-text {
		text-align: center;
		max-width: 700px;
		margin: 0 auto;
	}
}


#home-boxes {
	padding-bottom: 50px;
	.col-sm-4 {
		a {
			text-decoration: none;
		}
		.card {
			@extend .my-2;
			.card-img-top {
				position: relative;
				img {
					width: 100%;
				}
				.icon {
					width: 65px;
					height: 65px;
					position: absolute;
					left: 50%;
					bottom: -32px;
					margin-left: -32px;
					img {
						width: 100%;
					}
				}
			}
			.card-body {
				background: $lbrown;
				color: $white;
				text-transform: uppercase;
				font-size: 1.5rem;
				text-align: center;
				padding: 50px 10px 25px;
				line-height: 1.55rem;
				transition: all 0.3s;
				.card-text {
					line-height: 1.8rem;
				}
			}
			&:hover {
				.card-body {
					background: darken($lbrown,10%);
				}
			}
		}
	}
	.home-tasting {
	}
	.home-wines {

	}
	.home-club {}
}

#home-wines {
	.v65-productGroup-teaser {display: none;}
	.v65-productGroup {margin-bottom: 0px;}
	h2 {

	}


}

#home-awards {
	#awards {
		@media (min-width: 768px) {
			flex-wrap: nowrap;
		}

	}
	.col {
		display: flex;
		justify-content: center;
		img {
			width: 100%;
		}
		a {
			display:flex;
			align-items: center;
		}
	}
}

#home-awards, #home-weather {
	padding-bottom: 50px;
	text-align: center;
	@media (min-width: 992px) {
		padding-bottom: 50px;
	}
	.videoWrapper {
		height: 250px;
		padding: 0px;
		display: flex;
    	align-items: center;
    	flex-direction: column;
	}
	iframe {
	display: flex;
	position: relative;
	height: 250px;
    max-width: 760px;
    @media (min-width: 768px) {
    	width: 760px;
    }
	}
}


// ----------------------------------------------------------------------
// OVERLAY NAV
// ----------------------------------------------------------------------
/* The Overlay (background) */

.overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    display: none;
    height: 100%;
    position: fixed; /* Stay in place */
    z-index: 99999; /* Sit on top */
    left: 0;
    top: 0;
    background-color: $brown; /* Black w/opacity */
    overflow-x: hidden;
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */

}

/* Position the content inside the overlay */

.overlay-content {
    position: relative;
    padding-top: 50px;
    width: 100%; /* 100% width */
    text-align: center; /* Centered text/links */
    margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */

    .v65-layoutHeaderNav {
    	padding:0px;
    	text-align: left;
    	ul, li {list-style-type: none;}
    	li {
    		a {
    			text-decoration: none;
			    color: $llbrown;
			    padding: 5px 0px;
			    display: block;
			    border-bottom: 1px solid $lbrown;

    		}

    		ul {
    			padding-left:15px;

    		}

    	}
    	li.v65-home {
    		border-top: 1px solid $lbrown;
    	}
    }
    .utilities {
    	position: relative;
    	text-align: left;
    	.user-tools, #v65-modalCart {
    		height: auto;
		    display: block;
		    width: 100%;
		    a {
		    	text-decoration: none;
		    	color: $llbrown;
		    }
    	}


    	.icon-person, .icon-cart, .icon-phone {
    		color: $white;
    		padding: 0px 5px 0px 0px;
    	}

    	.telephoneNumber, .v65-yourAccount, .v65-login {
    		display: inline-block;
    		margin: 0px 20px 0px 0px;
    		float:none;
    		width:auto;
    	}
    	.telephoneNumber {
	    	a {
	    	    margin-top: 6px;
	    		display: inline-block;
	    	}
    	}

    	.v65-login {}

    	#v65-modalCart {
    		position:relative;
    		top: unset;
    		right: unset;
    		text-align: left;
    		padding: 20px 0px 20px;
    		font-size: 0.875em;
    		#v65-modalCartDropdown {
				position: relative;
			    width: 100%;
			    top: unset;
			    margin-top: 20px;
			    margin-bottom:20px;
			    display: block;
			    #v65-modalCartBody {
			    	.modalLinkAltBtn {
			    		display: none;
			    	}
			    }
			}

    	}

    	.social-icons {
    		text-align:left;
    		padding-bottom: 20px;
    		li {
    			margin-right: 0px;
    			padding: 0px 12px;
    			a {
    				color: $white;
    			}
    		}
    		&:first-child {padding-left: 0px;}
    	}
    }
}

/* Position the close button (top right corner) */

.closebtn {
    position: absolute;
    top: -35px;
    right: 26px;
    font-size: 60px !important; /* Override the font-size specified earlier (36px) for all navigation links */
    text-decoration: none !important;
    color: $white !important;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */

@media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .closebtn {
        font-size: 40px !important;
        top: 15px;
        right: 35px;
    }
}

.slick-controls {
	display: none;
}

// ----------------------------------------------------------------------
// WINE CLUB
// ----------------------------------------------------------------------

#clubs {
	hr {
		@extend .margin-lg;
	}
	.v65-clubSignUp-Teaser {
		display: block;
	}
	.col-sm-8 {
		@media (min-width: 576px) and (max-width: 767px) {
			width: 55%;
		}
		.row {
			@media (max-width: 991px) {
				display: block !important;
			}
			.col {
				margin-bottom: 20px;
				.border {
					position: relative;
					padding: 20px;

					@media (min-width: 992px) {
						padding: 20px 30px 60px;
					}
					ul {
						padding-left: 20px;
					}
					a {
						@extend .btn-primary;
						width: 100%;
						-webkit-appearance: none;
						@media (min-width: 992px) {
							position: absolute;
							width: calc(100% - 60px);
							bottom: 20px;
						}
					}
				}
			}
		}

	}
	.col-sm-4 {
		@media (min-width: 576px) and (max-width: 767px)  {
			width: 45%;
		}
		.box-img {
			min-height: 200px;
			background: url(../img/wines-img.jpg) no-repeat center center;
			background-size: cover;
			@media (min-width: 576px) {
				min-height: 420px;
			}
			@media (min-width: 768px) and (max-width: 991px) {
				min-height: 455px;
			}
			@media (min-width: 992px) and (max-width: 1199px) {
				min-height: 0px;
			}
			@media (min-width: 1200px) {
				min-height: 120px;
			}
		}
		.box-text {
			padding: 20px;
			@media (max-width: 575px) {
				margin-bottom: 50px;
			}
			h3 {
				text-transform: uppercase;
				color: $llbrown;
			}
			ul {
				padding-left: 20px;
				li {
					padding-bottom: 8px;
				}
			}
		}
	}

}


#v65-clubFormWrapper {
	#v65-fieldset-choices {
		width: auto !important;
		@media (min-width: 768px) {
			padding-right: 20px;
		}
		.v65-clubProductImageWrapper {
			text-align: left;
		}

	}
}

#members {
	.v65-group .v65-clubChoices-teaser p img {display:block;}
	.v65-clubChoices .v65-clubSubtotalWrapper, .v65-clubChoicesDescription {
		clear:both;
		float:none;
	}

}

.v65-clubChoicesProductsWrapper {display: flex; flex-flow: row wrap; justify-content: space-between;}
.v65-clubChoicesProductsRow {
	width: 50%;
	flex-flow: row wrap;
	.v65-clubChoicesProductTitle {
	    font-family: GillSansStd-Bold;
	}
}

.v65-clubChoices .v65-clubChoices-teaser {
	@media (min-width: 768px) {max-width: 525px;}
	@media (min-width: 1200px) {max-width: 600px;}
}

#v65-checkBilling, fieldset#v65-fieldset-choices, .v65-clubChoices, .v65-clubChoicesProductsWrapper {width: 100%;max-width:none;}

// ----------------------------------------------------------------------
// TASTING ROOM
// ----------------------------------------------------------------------

.badges {
	text-align: center;
	.TA_cdsratingsonlynarrow {
		display: inline-block;
    	padding: 8px 10px;

	}
}
#trade {
	.card-wrapper, .card-inner {
		width: 100%;
	}
}
#tasting, #trade {
	margin-top: 50px;
	.container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		padding: 0px;
		margin: 0px;
		.row {
			display: flex;
			flex-direction: row;
			width: 100%;
			.col-md {
				display: flex;
				p {
					flex-grow: 1;
				}
				a {
					height: auto;
					line-height: 1.2rem;
				}
			}
		}
	}
	.card-wrapper {
		display: inline-block;
		width: 100%;
		@media (min-width: 768px) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
		.card-inner {
			@media (min-width: 768px) {
			display: flex;
			flex-direction: row;
			}
			.card {
				margin-bottom: 20px;
				@media (min-width: 576px) {

				}
				@media (min-width: 768px) {
				display: flex;
				width: 100%;
				margin-right: 20px;
				flex-direction: column;
				}
				@media (min-width: 992px) {
				}
				&:last-child {
					margin-right: 0px;
				}
				p {
					flex-grow: 1;
				}
			}
		}
		.card-bottom {
			padding-bottom: 10px;
			a {
				height: auto;
			}
		}
	}
}

.card {
	.card-img-top {
		img {
			width: 100%;
		}
	}
	.card-body {
		h2 {
			@extend .subtitle;
		}
		p {
			margin-bottom: 10px;
		}

	}
	a {
		margin: 0px 20px 10px;
		    width: calc(100% - 40px);
		@extend .btn;
		@extend .btn-primary;
	}
}


// ----------------------------------------------------------------------
// BOOTSTRAP OVERRIDES
// ----------------------------------------------------------------------

.navbar-light .navbar-nav .nav-link {
	color: $dgray;
}


.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-link.active {
	color: $orange;
}

.navbar-toggler:focus {
	box-shadow: none;
}

footer nav li a, .v65-layoutHeaderNav li a {
	text-transform: capitalize;
}

// ----------------------------------------------------------------------
// WINE DIRECT OVERRIDES
// ----------------------------------------------------------------------

.user-tools .v65-yourAccount, .user-tools .v65-editProfile, .user-tools .v65-login, .user-tools .v65-logout {
	height: auto;
	line-height: normal;
	text-align: left;
}


.v65-product-pagination li.border {
	padding: 5px 15px;
    margin: 0px;

}

.v65-product-pagination li.border {
	border: none !important;
}

a.v65-product-quickView {
    padding: 8px 10px 5px;
    font-size: 0.8rem;
    border-radius: 0px;
    border-color: $orange;
    text-decoration: none;
    text-transform: uppercase;
    .icon-search {
    	padding-right: 5px;
    }
}


.dropDownHide {
	display: inherit;
}

#v65-signUpClubSummaryWrapper {
	@media (max-width: 767px) {
		float: none !important;
	    width: 100% !important;
	    margin-bottom: 20px;
	}
	@media (min-width: 992px) {
		top:0px;
		right: 0px;
		position: absolute;
	}
}

.v65-clubSignUp-Teaser {display: block;}
#v65-billingInformation div:first-child {display: inline-block;}

a.linkAltBtn, a.modalLinkAltBtn, a.modalLinkAltBtn, a.largeLinkBtn span, a.linkBtn span, a.modalLinkBtn span, a.linkAltBtn span, a.modalLinkAltBtn span, button.defaultBtn, button.modalBtn, button.largeBtn {
	border-radius: 0px;
}

.v65-cartCheckOutButtons {
	a.largeLinkBtn, button.modalBtn, a.linkBtn, a.modalLinkBtn {

		@extend .btn;
		@extend .btn-primary;
		line-height: 30px;
	}
}

#main {
	a.linkBtn {
		@extend .btn;
		@extend .btn-primary;
		line-height: 30px;
	}
	a.modalLinkBtn {
		background-color: #f66328 !important;
	}
}


#v65-cartHeader, #v65-checkoutCartSummaryWrapper {
		.v65-form button[type=submit] {
			margin:0px;
		}
}

body#shoppingcart {
	#banner, #nav-secondary {
		display: none;
	}
}

#sec-wines .v65-title {
	font-family: $font-regular;
	font-size: 1.8rem;
    line-height: 1;
    margin-bottom: 1em;
    border-top: 1px solid $llbrown;
    padding-top: 40px;
    margin-top: 40px;
}

#sec-wines .v65-wine-specs .v65-product-attributeLabel {width: 100px; text-align: left;}

#sec-wines #wine-profile-id .v65-product-attribute .v65-product-attributeLabel {text-transform: uppercase;}

#sec-wines .productReviews {
	form {
		width: 335px;
		fieldset {
				padding: 20px 5px 10px;
				border-color: $llbrown;
				input, textarea, select, button[type=submit] {
					width: 100%;
				}
				#isSubscribe {width: inherit;margin-bottom: 7px;}
		}
	}
}
#sec-wines .v65-product-socialBar {
	text-align: center;
	.v65-socialMediaBar-Twitter, .v65-socialMediaBar-FacebookLikeButton {
		float: none;
		display: inline-block;
	}
}





#v65-checkoutFormWrapper .v65-form fieldset div {overflow: initial;}

// ----------------------------------------------------------------------
// PRODUCT GROUPS & CATEGORIES
// ----------------------------------------------------------------------

.v65-product-title {
	line-height: 2rem;
	margin: 0px 0px 15px;
	font-size: 1.7rem !important;
	@extend .subtitle;

	a {
		color: $black;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}
.v65-product-teaser p {
		font-size: 16px;
	    line-height: 1.3;
		color: #6d6d6d;
		margin-bottom: 20px;
	    font-family: "GillSansStd-Light", sans-serif;
	}

.v65-product-inventoryMessage-inStock {
		font-style: italic;
		margin-bottom: 8px !important;
	}

.v65-product-reviewStats {
	a {
		font-size: 0.9rem;
	    margin-bottom: 8px;
	    font-weight: 500;
	    display: block;
		font-family: "GillSansStd-regular" ,sans-serif;
		text-decoration: none;
		color: $lbrown;
		&:hover {
			text-decoration: none;
		}
	}
}

.v65-product-addToCart {
	fieldset{
		display: flex;
	    justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		@media (min-width: 576px) and (max-width: 767px)  {
			flex-wrap: nowrap;
		}
		legend {display: none;}
		.v65-product-addToCart-priceWrapper {
			margin-bottom: 0;
			display: flex;
		    align-items: center;
		    margin-top: 8px;

		    @media (max-width: 991px)  {
		    	width: 100%;
		    }

		    .v65-product-addToCart-price{
			    font-weight: 400;
			    font-size: 20px;
			    color: #332f2c;
				margin-bottom: 0;
				font-family: "GillSansStd-regular" ,sans-serif;
			}
			.v65-product-addToCart-unitDescription{
				padding-left: 5px;
				padding-right: 8px;
			    font-size: 16px;
			    font-weight: 400;
				margin: 0;
				bottom: 7px;
				color: #332f2c;
				font-family: "GillSansStd-regular" ,sans-serif;
			}
			.v65-product-addToCart-productSKU {
				display: none;
			}
	    }
	    .v65-product-addToCart-quantity input{
			font-family: "GothamMedium" ,sans-serif;
			height: 2.9em;
    		line-height: 2.9em;
    		border: 1px solid #D8D8D8;
    		font-size: 0.9em;
    		min-width: 3em;
    		margin-top: 10px;
    		@media (min-width: 576px) and (max-width: 767px) {
    			margin-left: 20px;
    		}
		}
		.v65-product-addToCart-button {
			button {
				border-radius: 0;
			    height: 42px;
			    padding: 0px 15px;
			    min-width: 125px;
			    margin-top: 10px;
			    margin-left: 7px;
			    @extend .btn-primary;
			    @media (min-width: 576px) {

			    }
			    @media (min-width: 576px) and (max-width: 767px) {
			    	display: flex;
			    	justify-content: center;
			    	align-items: center;

			    	min-width: 140px;
			    }
			    @media (min-width: 992px) and (max-width: 1199px) {
			    	min-width: 125px;
			    }
			}
			span {
				padding: 0;
				margin: 0;
				font-size: 14px;
			}

		}
	}

}

.v65-quickView {
	background-color: $white;
	.v65-quickViewContent {

		display:flex;
		.v65-product-reviewStats strong {font-weight: normal;}
	}
	.v65-product-attributeLabel {
		font-family: $font-regular;
		font-size: 1.2rem;
	}
	.v65-quickView-close {
		.icon-cross {
		color: $black;
	    font-size: 2rem;
	    padding: 0px;
		}
	}
}


#v65-modalCartDropdown {
	#v65-modalCartBody {
		background: $white;
		.modalLinkAltBtn, .modalLinkBtn {
			border-radius: 0px;
		}
		.modalLinkBtn {
			background-color: $orange;
		}

	}
}


// ----------------------------------------------------------------------
// PRODUCT GROUPS
// ----------------------------------------------------------------------

.v65-productGroup-products {
	text-align: left;

	@media (min-width: 768px) {
		display: flex;
		flex-wrap: wrap;
	}
	@media screen and (max-width: 992px){

	}
	&:nth-of-type(even) {
		@media (min-width: 768px) {
			margin-right: 0px;
		}

	}
	.v65-productGroup-product {
		display: flex;
		margin: 0px 0px 30px;
		height: auto !important;
		@media (min-width: 575px) and (max-width: 767px) {
			width: 100%;
			display:flex;
		}
		@media (min-width: 768px) and (max-width: 991px) {
			margin: 0px 2% 30px 0px;

		}
		@media (min-width: 992px) {
			margin: 0px 4% 30px 0px;
			width: 48%;
		}
		&:nth-of-type(even) {

			@media (min-width: 768px) {
				margin-right: 0px;
			}
		}

		.productDescription {
			width: 75%;
			.v65-product-title {}
			.v65-product-teaser {
				height: auto !important;
			}
			.v65-product-reviewStats {

			}
			.v65-product-addToCart {
				fieldset {
					legend {

					}
				}
				.v65-product-addToCart-priceWrapper {
					.v65-product-addToCart-price {}
					.v65-product-addToCart-unitDescription {}
					.v65-product-addToCart-productSKU {}
				}
				.v65-product-addToCart-quantity {}
				.v65-product-addToCart-button {}


			}

		}
		.productPhoto {
			width: 25%;
		}


	}
}

// ----------------------------------------------------------------------
// PRODUCT CATEGORY 1UP CUSTOM
// ----------------------------------------------------------------------

#main {
	.v65-product1Up {
		display: flex;
		flex-direction: row-reverse;
		align-items: start;
		@media (min-width: 768px) {
			display: inline-flex;
			width: 48%;
    		margin: 0px 2% 50px 0px;
		}
		&:nth-of-type(odd) {
			margin-right: 0px;
		}
		.v65-product-photo {
			width: 25%;
		}
		.v65-product-infoWrapper {
			width: 75%;
			.v65-product-title {}
			.v65-product-teaser {}
			.v65-product-drilldownLink {}
			.v65-product-inventoryMessage {}
			.v65-product-reviewStats {}
			.v65-product-addToCart {}
			.v65-product-socialBar {}
		}
	}

}


// ----------------------------------------------------------------------
// TASTING ROOM
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// FORMS
// ----------------------------------------------------------------------

input, select, optgroup, textarea {
	padding: 10px 5px !important;
}
button.defaultBtn, button.modalBtn, button.largeBtn {
	@extend .btn-primary;
}

#v65-businessLocatorRadius {
	margin-top: 15px;
}

// ----------------------------------------------------------------------
// SPECIFICS
// ----------------------------------------------------------------------

.Buy-wine {
	#main .container {
		.intro {
			border-bottom: 1px solid $brown;
		    padding-bottom: 30px;
		    margin-bottom: 30px;
		}
		.v65-group {
			display: inline-flex;
			flex-direction: row-reverse;
			align-items: stretch;
			.v65-product-photo {
				width: 25%;
		    }

		    .v65-product-wrapper {
		    	width: 75%;

		    }
		    .v65-product-photo, .v65-product-wrapper {
		    	float: none;
		    	flex-wrap: wrap;
		    }

		}
		.v65-product1Up:nth-of-type(odd) {
			margin-right: 0px;
		}

	}
}


.Buy-wine-White {
	#intro {
		.container {
			@media (min-width: 768px) {
				display: flex;
				flex-wrap: wrap;
			}
			.v65-product1Up.v65-group {
			    display: flex;
			    flex-direction: row-reverse;
			    @extend .col-md-2;
			}
		}
	}

}

#v65-businessLocator {
	padding-top: 50px;
	p {font-size: 1.1rem;}

	.v65-form legend, #v65-memberFormWrapper legend {
		font-family: $font-regular;
	}
	.v65-form label, #v65-memberFormWrapper label {
		padding-bottom: 8px;
	    text-transform: uppercase;
	    font-size: 1.1rem;
	    letter-spacing: 1px;
	    margin-top: 10px;
	}
	.v65-form textarea, .v65-form select {
		font-family: $font-light;
	    font-size: 0.6em;

	}
	input[type='text'], input[type='password'], input[type='username'], input[type='tel'], input[type='email'], textarea, input[type='number'] {
		font-size: 0.9rem;
	}
	button span {font-size: 1rem;}

	#v65-map_canvas {top: 50px;}
}


// ----------------------------------------------------------------------
// TEMPORARY SOLUTIONS
// ----------------------------------------------------------------------

.About-Recipes {
	h3 img {display: none;}
}

.Buy-wine-Trade-Professionals {
	#prefooter-wines {display: none;}
}

// ----------------------------------------------------------------------
// INSTAGRAM
// ----------------------------------------------------------------------

body.instagram {
	background: url('https://www.fortberens.ca/assets/fort-berens/img/instagram/bg-instagram.jpg') no-repeat top center;
	background-size: contain;

	header {
		padding: 50px 0px 30px;
		text-align: center;
		background: transparent;
		.logo {
			max-width: 120px;
    		margin: 0 auto;
    		img {
    			width: 100%;
    		}
		}
		p {
			font-size: 1.8rem;
		    font-family: $font-regular;
		    padding-top: 20px;
		}
	}

	.sec-instagram {
		max-width: 600px;
    	margin: 0 auto;
    	a {
    	    text-decoration: none;
		    padding: 8px 10px;
		    width: 100%;
		    text-transform: uppercase;
		    border: 2px solid $orange;
		    display: block;
		    text-align: center;
		    margin-bottom: 15px;
		    transition: all 0.3s;
		    &:hover {
		    	background-color: $orange;
		    	color: $white;
		    }
    	}
	}

	.sec-social {
		padding-top: 20px;
		.social-icons {
			text-align: center;
			ul li {
				padding: 0px 10px;
				a span.fab {
				font-size: 1.5rem;
    			color: $black;
				}
			}
		}
	}

}
